




























import { Prop, Component, Mixins } from 'vue-property-decorator'
// mixins
import { BeatAlreadyInCart } from '@/mixins/beat-already-in-cart'
// const
import { ModalStore, ModalType } from '~/store/modal'
// components
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { PlayerStore } from '~/store/player'
import { BeatStore } from '~/store/beat'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'

@Component({ components: { VLoadSpinner } })
export default class BeatPage extends Mixins(BeatAlreadyInCart, BeatImageOrProducerLogo) {
    @Prop() beat!: IBeat

    ModalType = ModalType

    @PlayerStore.State('isAudioPaused') isAudioPaused: boolean
    @PlayerStore.Getter('beatPlaying') beatPlaying: IBeat
    @BeatStore.Action('demoDownloadBeat') demoDownloadBeat: (beat: IBeat) => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: () => void

    get backgroundImgLink() {
        if (!this.beat) return null
        return `background-image: url('${this.beatImageOrProducerLogo(this.beat)}')`
    }

    togglePlayStatus() {
        // create a playlist array of 1 beat, and play that beat
        this.$store.dispatch('player/togglePlayStatus', { beats: [this.beat], index: 0 })
    }
}
