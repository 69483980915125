













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Beat from '~/views/Beat.vue'
import PBeatTable from '@elitepage/components/PBeatTable.vue'
import { BeatFilterType, fetchBeats, fetchSingleBeat } from '~/api/beats'
import VLoadSpinner from '~/components/VLoadSpinner.vue'

@Component({
	components: { Beat, PBeatTable, VLoadSpinner }
})
export default class PBeat extends Vue {
	@Prop({ type: Number }) id!: IBeat['id']
	beat: IBeat = null
	relatedBeats: IBeat[] = []
	isLoading = false

	async fetchRelatedBeats() {
		const artistTypeSlug = this.beat.artist_type.slug
		const relatedBeats = await fetchBeats({ filterType: BeatFilterType.Related, slug: artistTypeSlug, producerId: this.beat.producer.id })
		this.relatedBeats = relatedBeats.filter((item) => item.id !== this.beat.id)
	}

	@Watch('$route', { immediate: true })
	async route() {
		this.isLoading = true
		this.beat = await fetchSingleBeat(this.id)
		this.fetchRelatedBeats()
		this.isLoading = false
	}
}
